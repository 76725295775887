import { setCookie } from "./cookie";
import { uClone, objectsEqualTo } from "./object";
import { serializer, unserializer } from "./serialize.js";

const STORAGE_PREFIX = "m-app:";

export function getStorageValue(name: string, defaultValue = "") {
    return typeof window === "object"
        ? localStorage.getItem(`${STORAGE_PREFIX}${name}` || "")
        : defaultValue;
}
export function setStorageValue(name: string, value: any) {
    return typeof window === "object"
        && localStorage.setItem(`${STORAGE_PREFIX}${name}`, value);
}
export function removeStorageValue(name: string) {
    return typeof window === "object"
        && localStorage.removeItem(`${STORAGE_PREFIX}${name}`);
}
export function getSessionStorageValue(name: string, defaultValue = "") {
    return typeof window === "object"
        ? unserializer(window.sessionStorage.getItem(`${STORAGE_PREFIX}${name}`) || "")
        : defaultValue;
}
export function setSessionStorageValue(name: string, value: any) {
    return typeof window === "object"
        && window.sessionStorage.setItem(`${STORAGE_PREFIX}${name}`, serializer(value));
}
export function removeSessionStorageValue(name: string) {
    return typeof window === "object"
        && window.sessionStorage.removeItem(`${STORAGE_PREFIX}${name}`);
}

type Callback = (newValue?: any, oldValue?: any) => void;

class _Storage {
    private _data: Record<string, any> = {};
    private _watches: Record<string, Map<string, Callback>> = {};

    constructor(){
        if(typeof window === "object") {
            for(let i = 0;i < localStorage.length; ++i){
                const key = localStorage.key(i) as string;

                if(key.startsWith(STORAGE_PREFIX)) {
                    const _key = key.replace(STORAGE_PREFIX, "");
                    this._data[_key] = getStorageValue(_key);
                }
            }
            for(let i = 0;i < window.sessionStorage.length; ++i){
                const key = window.sessionStorage.key(i) as string;

                if(key.startsWith(STORAGE_PREFIX)) {
                    const _key = key.replace(STORAGE_PREFIX, "");
                    this._data[_key] = getSessionStorageValue(_key);
                }
            }

            window.addEventListener("storage", (event) => {
                if(event.key?.startsWith(STORAGE_PREFIX)){
                    const _key = event.key.replace(STORAGE_PREFIX, "");
                    const value = unserializer(event.newValue || "");

                    this.set(_key, value);
                }
            });
        }
    }

    set(name: string, value: any, options?: {saveOnLocal?: boolean, saveOnSession?: boolean, saveOnCookie?: boolean}) {
        const oldValue = this._data[name];
        const { saveOnLocal = false, saveOnSession = false, saveOnCookie = false } = options || {};

        if(!objectsEqualTo(value, oldValue)) {
            this._data[name] = value;
            if(this._watches[name] instanceof Map){
                for(const cb of this._watches[name].values()) {
                    cb(value, oldValue);
                }
            }

            if(saveOnLocal) {
                setStorageValue(name, value);
            }
            if(saveOnSession) {
                setSessionStorageValue(name, value);
            }
            if(saveOnCookie) {
                setCookie(name, value);
            }
        }
    }
    get(name: string, defaultValue: any = null) {
        return uClone(typeof this._data[name] === "undefined" ? defaultValue : this._data[name]);
    }

    remove(name: string) {
        delete this._data[name];
        removeSessionStorageValue(name);
        removeStorageValue(name);
    }
}

const uStorage = new _Storage();
export function useUStorage(){
    return uStorage;
}