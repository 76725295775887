import { default as _91id_93c1bhcosRpyMeta } from "/nuxt/pages/airdrops/[id].vue?macro=true";
import { default as indexd2QrcA8nXLMeta } from "/nuxt/pages/airdrops/index.vue?macro=true";
import { default as coin7AkZl1GGQwMeta } from "/nuxt/pages/coin.vue?macro=true";
import { default as _401zVTMFc14AUMeta } from "/nuxt/pages/error/401.vue?macro=true";
import { default as profileN3CLLoDIpLMeta } from "/nuxt/pages/profile.vue?macro=true";
import { default as tasksV3VZIrfyCEMeta } from "/nuxt/pages/tasks.vue?macro=true";
import { default as component_45stubJxeqNcsYLOMeta } from "/nuxt/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubJxeqNcsYLO } from "/nuxt/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "airdrops-id",
    path: "/airdrops/:id()",
    meta: _91id_93c1bhcosRpyMeta || {},
    component: () => import("/nuxt/pages/airdrops/[id].vue")
  },
  {
    name: "airdrops",
    path: "/airdrops",
    component: () => import("/nuxt/pages/airdrops/index.vue")
  },
  {
    name: "coin",
    path: "/coin",
    component: () => import("/nuxt/pages/coin.vue")
  },
  {
    name: "error-401",
    path: "/error/401",
    meta: _401zVTMFc14AUMeta || {},
    component: () => import("/nuxt/pages/error/401.vue")
  },
  {
    name: "profile",
    path: "/profile",
    component: () => import("/nuxt/pages/profile.vue")
  },
  {
    name: "tasks",
    path: "/tasks",
    component: () => import("/nuxt/pages/tasks.vue")
  },
  {
    name: component_45stubJxeqNcsYLOMeta?.name,
    path: "/",
    component: component_45stubJxeqNcsYLO
  }
]