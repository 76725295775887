export default defineNuxtPlugin(() => {
    addRouteMiddleware("telegramBtns", (to) => {
        if (import.meta.client) {
            const tg = (window as any).Telegram?.WebApp;
            if(tg) {
                if (to.name !== DEFAULT_PAGE_NAME) {
                    tg.BackButton.show();
                    tg.BackButton.onClick(()=> {
                        return navigateTo("/");
                    });
                } else {
                    tg.BackButton.hide();
                }
            }
        }
    }, { global: true });
});