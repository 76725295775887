import clickAway from "./clickAway";
import attr from "./attr";
import format from "./format";
import scrollInView from "./scrollInView";

export default [
    clickAway,
    attr,
    format,
    scrollInView,
];