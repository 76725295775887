export const FOOTER_ITEMS = [
    {
        name: "airdrops",
        label: "apps",
        linkName: "airdrops",
        icon: "tg-rr-apps",
    },
    {
        name: "tasks",
        label: "tasks",
        linkName: "tasks",
        icon: "tg-rr-completed",
    },
    {
        name: "profile",
        label: "profile",
        linkName: "profile",
        icon: "tg-rr-user",
    },
    // {
    //     name: "coin",
    //     label: "coin",
    //     linkName: "coin",
    //     icon: "tg-rr-circle-b"
    // }
]