import { formatNumber } from "@/utils/numbers";
import { objectsEqualTo } from "@/utils/object";

function formatDirectiveFunc(el: HTMLElement, binding: any) {
    const { value, oldValue } = binding;

    if(!objectsEqualTo(value, oldValue)) {
        el.innerHTML = formatNumber(value);
    }
}

const formatDirective = {
    name: "format",
    directive: {
        mounted: formatDirectiveFunc,
        updated: formatDirectiveFunc,
    },
};

export default formatDirective;