import axios, { type AxiosInstance, type AxiosResponse } from "axios";
import type { IntApiServiceOptions, IntRequestConfig } from "@m-app";
import { ConflictError, NotAcceptableError } from "./errors";
// import { parseGzipedData, decodeBase64 } from "./index";
const DEFAULT_SERVICE_OPTIONS: IntRequestConfig = {
    cachedEnable: false,
    transformResponseType: "self",
    inDuplicate: "send",
    headers: {},
    authHeader: "",
};

class ApiService {
    private _axios: AxiosInstance;
    private _services: Record<string, IntRequestConfig> = {};
    private _requests: Record<string, IntRequestConfig> = {};

    constructor(options: IntApiServiceOptions) {
        this._axios = axios.create(options);
        this._services = this._prepareServices(options.services);
    }

    _prepareServices(services: IntApiServiceOptions["services"]){
        const result: Record<string, IntRequestConfig> = {};

        for(const sName in services) {
            const service = (typeof services[sName] === "string" ? { url: services[sName] } : services[sName]) as IntRequestConfig;

            if(typeof service === "object") {
                result[sName] = {
                    ...DEFAULT_SERVICE_OPTIONS,
                    ...service,
                };
            }
        }

        return result;
    }

    _transformResponse(type: IntRequestConfig["transformResponseType"], response: AxiosResponse["data"]){
        let result;

        if(typeof type === "function") {
            result = type(response);
        }
        // else if(type === "base64") {
        //     result = decodeBase64(response);
        // } else if(type === "gzip") {
        //     result = parseGzipedData(response);
        // } else if(type === "dataGzip") {
        //     result = {
        //         ...response,
        //         data: parseGzipedData(response.data),
        //     };
        // }
        else {
            result = response;
        }
        return result;
    }

    _generateRequestId(id: IntRequestConfig["id"]): string | number {
        const typeofId = typeof id;
        let result: number | string;

        if(typeofId === "object") {
            const keys = Object.keys(id as object).sort();
            result = "";

            for(const key of keys){
                result += `${key}:${(id as Record<string, string>)[key]},`;
            }
        } else if(["number", "string"].includes(typeofId)) {
            result = id as string;
        } else {
            result = Date.now();
        }

        return result;
    }

    _checkDuplicate(requestId: string, options: IntRequestConfig) {
        if(typeof this._requests[requestId] === "object") {
            const inDuplicate = options.inDuplicate || this._requests[requestId].inDuplicate;
            if(inDuplicate === "cancel-first"){
                this._requests[requestId].abortController?.abort();
            } else if(inDuplicate === "cancel"){
                throw new Error("Canceled");
            }
        }
    }

    _createRequestConfig(serviceName:string, options: IntRequestConfig) {
        const requestConfig = {
            ...this._services[serviceName],
            ...options,
        };

        const headers = (requestConfig.headers || {}) as Record<string, string>;

        if(requestConfig.abortController instanceof AbortController === false) {
            requestConfig.abortController = new AbortController();
        }

        if(requestConfig.authHeader){
            headers.Authorization = requestConfig.authHeader;
        }

        if (typeof requestConfig.uparams !== "undefined") {
            for(const name in requestConfig.uparams){
                requestConfig.url = requestConfig.url?.replace(`:${name}`, requestConfig.uparams[name]);
            }
        }
        if (typeof requestConfig.querys !== "undefined") {
            let _querys = "";
            for(const [key, value] of Object.entries(requestConfig.querys)){
                const _sign = _querys ? "&" : "?";
                _querys = _querys + (`${_sign}${key}=${value}`);
            }
            requestConfig.url = requestConfig.url + _querys;
        }

        if(typeof requestConfig.form !== "undefined"){
            const form = new FormData();

            for(const name in requestConfig.form){
                form.append(name, requestConfig.form[name]);
            }

            requestConfig.data = form;
        }

        requestConfig.headers = headers;
        requestConfig.onUploadProgress = options.onUploadProgress;

        return requestConfig;
    }

    send<T = any>(serviceName: string, options: IntRequestConfig = {}) : Promise<T>{
        return new Promise((resolve, reject) => {
            (async () => {
                try {
                    if(typeof serviceName === "string" && typeof this._services[serviceName] === "object") {
                        const requestId = `${serviceName}@${this._generateRequestId(options.id)}`;
                        this._checkDuplicate(requestId, options);

                        const requestConfig = this._createRequestConfig(serviceName, options);
                        this._requests[requestId] = requestConfig;
                        await this._axios.request(requestConfig).then((response) => {
                            resolve(this._transformResponse(requestConfig.transformResponseType, response.data));
                        }).catch((error) => {
                            if(import.meta.server) throw new SsrError(error);
                            else if (error.response) {
                                switch(error.response.status) {
                                    case 401:
                                        window.location.href = "/error/401";
                                        break;
                                    case 403:
                                        throw new ForbidenError(error);
                                    case 406:
                                        throw new NotAcceptableError(error);
                                    case 409:
                                        throw new ConflictError(error);
                                    default:
                                        throw error;
                                }
                            }
                        });
                    } else {
                        throw new Error(`"${serviceName}" is not exists`);
                    }
                } catch (error) {
                    console.error(error);
                    reject(error);
                }
            })();
        });
    }
}

export default ApiService;