import { postEvent, type InitDataParsed } from "@telegram-apps/sdk";
import { useI18n } from "vue-i18n";
import language from "@/config/language";
import type { IntAccountConfig, IntLanguageConfig, IntUserData, TypePlatform } from "@m-app";
import type ApiService from "@/utils/apiService";

export const useUser = defineStore("m-app:user", () => {
    const nuxtApp = useNuxtApp();
    const apiService = nuxtApp.$apiService as ApiService;
    const { setLocale } = useI18n();

    const tgInitData = ref<InitDataParsed>(null as any);
    const tgInitDataRaw = ref("");
    const userData = ref<IntUserData>(null as any);
    const userDataLoading = ref(false);
    const accountConfig = ref<IntAccountConfig>({} as IntAccountConfig);
    const userPlatform = ref<TypePlatform>("all");
    const initialLanguage = useState("userLanguage");
    const selectedLanguage = ref(initialLanguage.value);
    const showChoosingLangPage = ref(false);
    const isRtlLang = computed(() => language.SUPPORTED_LANGUAGES.find(_lang => _lang.name === selectedLanguage.value.name)?.isRtl);
    const userId = computed(() => tgInitData.value?.user?.id || "");
    const displayName = computed(() =>
        ((tgInitData.value?.user?.firstName as string) + tgInitData.value?.user?.lastName) ||
        tgInitData.value?.user?.username || "user",
    );

    function setTgData(){
        const { initDataRaw, initData, platform } = tgParams();
        tgInitDataRaw.value = initDataRaw as string;
        tgInitData.value = initData as InitDataParsed;
        userPlatform.value = platform.startsWith("web")? "web" : platform as TypePlatform;

        setTimeout(setTgData, 3500000);
    }

    function initTg() {
        let isSupport = true;
        if(tgInitDataRaw.value === "") {
            try {
                setTgData();
                postEvent("web_app_expand");
                postEvent("web_app_setup_settings_button", {
                    is_visible: true,
                });
                postEvent("web_app_set_header_color", { color: "#161E36" });
                postEvent("web_app_set_background_color", { color: "#161E36" });
                postEvent("web_app_setup_main_button", {
                    is_visible: false,
                });
                postEvent("web_app_setup_swipe_behavior", {
                    allow_vertical_swipe: false,
                });
            } catch {
                isSupport = false;
            }
        }
        return isSupport;
    }

    async function getUserData() {
        userDataLoading.value = true;
        apiService.send("userAccount", {
            authHeader: tgInitDataRaw.value,
        }).then(data => {
            userData.value = data.data;
            selectedLanguage.value = userData.value.language ? 
                language.SUPPORTED_LANGUAGES.find(_lang => _lang.name === userData.value.language):
                selectedLanguage.value;
            showChoosingLangPage.value = !userData.value.language;            
        }).catch(error => {
            if(error instanceof ForbidenError) {
                // in 403 case tgInitDataRaw is exp
                setTgData();
                getUserData();
            }
        }).finally(() => {
            userDataLoading.value = false;
        });
    }

    async function getAccountConfig() {
        apiService.send("accountConfig", {
            authHeader: tgInitDataRaw.value,
        }).then(data => {
            accountConfig.value = data.data;
        }).catch(error => {
            console.error("Error getting accountConfig:", error);
        });
    }

    async function updateAccount(accountData: { language: "fa" | "en" }) {
        apiService.send("updateAccount", {
            authHeader: tgInitDataRaw.value,
            data: accountData,
        }).catch(error => {
            console.error("Error getting accountConfig:", error);
        });
    }

    function changeLang(lang: IntLanguageConfig) {        
        selectedLanguage.value = lang;
        updateAccount({ language: lang.name });
    }
    watch(() => selectedLanguage.value, (lang: IntLanguageConfig) => {
        setLocale(lang.name);
        if(document){
            const html = document.querySelector("html") as HTMLHtmlElement;        
            html.setAttribute("dir", isRtlLang.value ? "rtl" : "ltr");
            html.setAttribute("lang", lang.name);
        }
        setCookie("userLanguage", lang.name);
    }, { immediate: true });
    return {
        tgInitData,
        tgInitDataRaw,
        userId,
        displayName,
        userData,
        userDataLoading,
        accountConfig,
        userPlatform,
        selectedLanguage,
        isRtlLang,
        showChoosingLangPage,
        initTg,
        getUserData,
        getAccountConfig,
        changeLang,
    };
});