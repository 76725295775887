export class SsrError extends Error {
    constructor(message: string) {
        super(message);
        this.name = "SsrError";
    }
}
export class ForbidenError extends Error {
    constructor(message: string) {
        super(message);
        this.name = "ForbidenError";
    }
}
export class ConflictError extends Error {
    constructor(message: string) {
        super(message);
        this.name = "ConflictError";
    }
}
export class NotAcceptableError extends Error {
    constructor(message: string) {
        super(message);
        this.name = "NotAcceptableError";
    }
}