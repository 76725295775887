interface _Serializer<T> {
    read(raw: string): T
    write(value: T): string
}

type _Types = "boolean" | "object" | "number" | "any" | "string" | "map" | "set" | "date"
const _serializers: Record<_Types, _Serializer<any>> = {
    boolean: {
        read: (v: any) => v === "true",
        write: (v: any) => String(v),
    },
    object: {
        read: (v: any) => JSON.parse(v),
        write: (v: any) => JSON.stringify(v),
    },
    number: {
        read: (v: any) => Number.parseFloat(v),
        write: (v: any) => String(v),
    },
    any: {
        read: (v: any) => v,
        write: (v: any) => String(v),
    },
    string: {
        read: (v: any) => v,
        write: (v: any) => String(v),
    },
    map: {
        read: (v: any) => new Map(JSON.parse(v)),
        write: (v: any) => JSON.stringify(Array.from((v as Map<any, any>).entries())),
    },
    set: {
        read: (v: any) => new Set(JSON.parse(v)),
        write: (v: any) => JSON.stringify(Array.from(v as Set<any>)),
    },
    date: {
        read: (v: any) => new Date(v),
        write: (v: any) => v.toISOString(),
    },
};

function _getType(value: any): _Types {
    let result: _Types = "any";

    switch(typeof value) {
        case "object":
            if(value instanceof Date) {
                result = "date";
            } else if(value instanceof Map) {
                result = "map";
            } else if(value instanceof Set){
                result = "set";
            } else {
                result = "object";
            }
            break;
        case "string":
            result = "string";
            break;
        case "number":
            result = "number";
            break;
        case "boolean":
            result = "boolean";
            break;
    }

    return result;
}

export function serializer(value: any): string {
    const type = _getType(value);

    return JSON.stringify({
        type,
        value: _serializers[type].write(value),
    });
}
export function unserializer(value: string) {
    let result = null;

    try{
        const _value = JSON.parse(value);
        result = typeof _value === "object" && typeof _serializers[_value?.type as _Types] !== "undefined"
            ? _serializers[_value?.type as _Types].read(_value.value)
            : null;
    } catch(_) {
        //
    }

    return result;
}