import { BASE_URL } from "@/config/constants";

export const useApiEndpoints = () => {

    const enviroment = useRuntimeConfig().public.appEnviroment;
    const ENVIROMENT_SECTION = enviroment === "dev" ? "-dev" : "";

    const BASE_AIRDROP_URL = `${BASE_URL}/ma-airdrop-api${ENVIROMENT_SECTION}/api/v1`;
    const BASE_VISIT_AIRDROP_URL = `${BASE_URL}/ma-visit-api${ENVIROMENT_SECTION}/api/v1`;
    const BASE_ACCOUNT_URL = `${BASE_URL}/ma-account-api${ENVIROMENT_SECTION}/api/v1`;
    const BASE_TASK_URL = `${BASE_URL}/ma-task-api${ENVIROMENT_SECTION}/api/v1`;
    const API_SERVICES_ENDPOINTS = {
        userAccount: { url: `${BASE_ACCOUNT_URL}/accounts/me` },
        updateAccount: { url: `${BASE_ACCOUNT_URL}/accounts/update`, method: "patch" },
        accountConfig: { url: `${BASE_ACCOUNT_URL}/config` },

        airdrops: { url: `${BASE_AIRDROP_URL}/airdrops`, method: "get" },
        airdrop: { url: `${BASE_AIRDROP_URL}/airdrops/:id`, method: "get" },
        myAirdrops: { url: `${BASE_AIRDROP_URL}/airdrops/mine`, method: "get" },
        addAirdrop: { url: `${BASE_AIRDROP_URL}/airdrops/mine/add`, method: "post" },

        visitAirdrop: { url: `${BASE_VISIT_AIRDROP_URL}/visits/add`, method: "post" },

        tasks: { url: `${BASE_TASK_URL}/tasks`, method: "get" },
        claimTaskReward: { url: `${BASE_TASK_URL}/tasks/claim`, method: "post" },
    };

    // const CDN_ASSETS_URL = `https://cdn.arz.digital/ma-${enviroment === "dev"? "dev" : "default"}/arz-mini-app/mini-app/assets`;
    // temporary
    const CDN_ASSETS_URL = "https://cdn.arz.digital/ma-dev/arz-mini-app/mini-app/assets";

    return {
        API_SERVICES_ENDPOINTS,
        CDN_ASSETS_URL,
    };
};