const SHORT_FORMAT_SEPRATORS = [{
    value: 1e18,
    symbol: "E",
}, {
    value: 1e15,
    symbol: "P",
}, {
    value: 1e12,
    symbol: "T",
}, {
    value: 1e9,
    symbol: "B",
}, {
    value: 1e6,
    symbol: "M",
}, {
    value: 1e3,
    symbol: "K",
}];

export function numberWithCommas(number: string | number) {
    let result = "";

    if (!isNaN(number as number)) {
        const sNumber = number.toString().split(".");
        result = sNumber[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        if(typeof sNumber[1] !== "undefined") result += "." + sNumber[1];
    }

    return result;
}

export function basicFormatNumber(number: number | string, fd?: number, commas = false): string {
    const nValue = Number(number);
    let result = "";

    if(!isNaN(nValue)) {
        if(nValue == 0) {
            fd = 0;
        } else if (fd == undefined){
            fd = Math.abs(nValue) < 1 ? 6 : 2;
        }

        if (fd >= 0) {
            const _result = nValue.toFixed(fd);
            const array = _result.split(".");

            if(array.length === 2 && (array[0] === "0" && Number(array[1]) === 0)) {
                const _value = nValue.toFixed(18).split(".")[1].replace(/^0+/, "");
                const length = 18 - _value.length;
                result = `${array[0]}.${"0".repeat(length)}${_value.slice(0, Math.max(1, fd - length))}`;
            } else {
                result = _result;
            }
        }
        else {
            result = nValue.toString();
        }

        if (commas) {
            result = numberWithCommas(result);
        }
    }

    return result;
}

export function advanceFormatNumber(value: number): string{
    let result: number | string = 0;

    if(typeof value === "number"){
        if (value >= 1) {
            result = basicFormatNumber(value, 2, true).replace(/0+$/, "").replace(/\.+$/, "");
        } else if (value >= .000001) {
            result = basicFormatNumber(value, 6, true).replace(/0+$/, "").replace(/\.+$/, "");
        }else if(value < .000001){
            if(value == 0) {
                result = "0";
            } else{
                const stringValue = value.toFixed(18).split(".")[1].replace(/0+$/, "");
                const _value = (stringValue as any).reverse().replace(/0+$/, "").reverse();
                const zeroLength = stringValue.length - _value.length;

                result = `0.${"0".repeat(zeroLength)}${_value.slice(0, 10 - zeroLength)}`;
            }
        }
    }

    return result as string;
}

export function shortenFormatNumber(number: number | string, fd: number, seprators: typeof SHORT_FORMAT_SEPRATORS = SHORT_FORMAT_SEPRATORS) {
    const result = {
        value: "",
        symbol: "",
        vp: "",
    };

    if(!isNaN(number as number)) {
        const nNumber = Number(number);

        if(nNumber < 1000) {
            result.vp = result.value = basicFormatNumber(nNumber, fd, true);
        } else {
            for(const seprator of seprators) {
                if(nNumber >= seprator.value) {
                    const _value = nNumber / seprator.value;
                    result.value = basicFormatNumber(_value, fd, true);
                    result.symbol = seprator.symbol;
                    result.vp = `${result.value}${result.symbol}`;
                    break;
                }
            }
        }
    }

    return result;
}

export function toArrayNumber(value: number | number[]) : number[]{
    const result = new Set<number>();

    if(!Array.isArray(value)){
        value = [value];
    }

    for(const v of value){
        const _v = Number(v);
        if(!isNaN(_v)){
            result.add(_v);
        }
    }

    return Array.from(result);
}

export function formatNumber(
    value: { type?: "short" | "adv", value: number | string, signObf?: boolean, prefix?: string, postfix?: string, fd?: string | number, comma?: boolean },
) {
    const type = value.type || "simple";
    const sign = Number(value.value) < 0 ? "-" : "";
    const _value = Math.abs(Number(value.value));
    let text = "";
    switch(type) {
        case "short":
            text = shortenFormatNumber(_value, Number(value.fd) || 2).vp;
            break;
        case "adv":
            text = advanceFormatNumber(_value);
            break;
        default:
            text = basicFormatNumber(_value, Number(value.fd), value.comma);
    }
    return `${value.signObf ? sign : ""}${value.prefix || ""}${value.signObf ? "" : sign}${text || value.value}${value.postfix || ""}`;
}