function attrDirectiveFunc(el: HTMLElement, binding: any) {
    const { value } = binding;

    if(typeof value === "object" && value !== null) {
        for(const key in value) {
            if(value[key]) {
                el.setAttribute(key, typeof value[key] === "boolean" ? key : value[key]);
            } else {
                el.removeAttribute(key);
            }
        }
    }
}
const attrDirective = {
    name: "attr",
    directive: {
        mounted: attrDirectiveFunc,
        updated: attrDirectiveFunc,
    },
};

export default attrDirective;