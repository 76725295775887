export function setCookie(cname: string, cvalue: string, exSeconds?: number) {
    if(typeof window === "object") {
        const cookie = [`${cname}=${cvalue}`];
        if(typeof exSeconds === "number") {
            const date = new Date();
            date.setTime(date.getTime() + (exSeconds * 1000));
            cookie.push(`expires=${date.toUTCString()}`);
        }
        // Set a cookie with SameSite=None to allow it to work within an iframe
        cookie.push("path=/; SameSite=None; Secure");
        document.cookie = cookie.join(";");
    }
}
export function getCookie(cname: string) {
    let value = "";
    if(typeof window === "object") {
        const name = cname + "=";
        const decodedCookie = decodeURIComponent(document.cookie).split(";");

        for(const cookie of decodedCookie) {
            const _cookie = cookie.trim();
            if (_cookie.indexOf(name) == 0) {
                value = _cookie.substring(name.length, _cookie.length);
                break;
            }
        }
    }

    return value;
}