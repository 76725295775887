import type ApiService from "@/utils/apiService";
import type { IntTaskData } from "@m-app";

export const useTask = defineStore("m-app:task", () => {
    const nuxtApp = useNuxtApp();
    const CDN_ASSETS_URL = (nuxtApp.$apiEndpoints as any).CDN_ASSETS_URL;
    const apiService = nuxtApp.$apiService as ApiService;
    const userStore = useUser();
    const tasks = ref<IntTaskData[]>([]);
    const taskClaimLoading = ref(false);
    const publicRuntimeconfig = useRuntimeConfig().public;
    const referTask = computed(() => {
        return {
            id: "refer",
            name: "task.share & gain",
            logo: `${CDN_ASSETS_URL}/accounts/refer/b7cd8253c74f2d782d901865f54a6dc7.svg`,
            longButton: "invite now",
            button: "invite",
            points: userStore.accountConfig.refer_points,
            description: { path: "invite desc", value: { number: userStore.accountConfig.refer_points / 1000 }},
            done: false,
            checkTime: 0,
            referUrl: `${publicRuntimeconfig.appBotName}/${publicRuntimeconfig.appName}?startapp=referrer_${userStore.userId}`,
        };
    });

    async function getTasks() {
        try {
            const data = await apiService.send("tasks", {
                authHeader: userStore.tgInitDataRaw,
                querys: { platform: userStore.userPlatform },
            });
            tasks.value = data.data;
        } catch (error) {
            console.error("Error getting tasks:", error);
        }
    }
    async function claimTaskReward(id: number) {
        taskClaimLoading.value = true;
        try {
            await apiService.send("claimTaskReward", {
                authHeader: userStore.tgInitDataRaw,
                data: { id: +id },
            });
        } catch (error) {
            console.error("Error claiming task reward:", error);
            throw error;
        } finally {
            taskClaimLoading.value = false;
        }
    }
    return {
        tasks,
        taskClaimLoading,
        referTask,
        getTasks,
        claimTaskReward,
    };
});