import revive_payload_client_4sVQNw7RlN from "/nuxt/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/nuxt/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/nuxt/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/nuxt/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/nuxt/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/nuxt/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/nuxt/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/nuxt/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/nuxt/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_VdOeuw25Mb from "/nuxt/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/nuxt/.nuxt/sentry-client-config.mjs";
import plugin_tbFNToZNim from "/nuxt/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import switch_locale_path_ssr_5csfIgkrBP from "/nuxt/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/nuxt/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import directives_8CcCirWtnE from "/nuxt/plugins/directives.ts";
import initialize_0ZT37Pxz4i from "/nuxt/plugins/initialize.ts";
import telegramBtns_q04eC4QRrg from "/nuxt/plugins/telegramBtns.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  sentry_client_VdOeuw25Mb,
  sentry_client_config_o34nk2sJbg,
  plugin_tbFNToZNim,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  directives_8CcCirWtnE,
  initialize_0ZT37Pxz4i,
  telegramBtns_q04eC4QRrg
]