import { useApiEndpoints } from "@/config/apiEndPoints";
import { DEFAULT_LANGUAGE } from "@/config/defaults";
import language from "@/config/language";
import ApiService from "@/utils/apiService";
import { useI18n } from "vue-i18n";

export default defineNuxtPlugin((nuxtApp) => {
    const apiEndpoints = useApiEndpoints();
    const _apiService = new ApiService({ services: apiEndpoints.API_SERVICES_ENDPOINTS });
    nuxtApp.provide("apiService", _apiService);
    nuxtApp.provide("apiEndpoints", apiEndpoints);

    nuxtApp.hook("vue:setup", () => {
        if(import.meta.client){
            const userStore = useUser();
            userStore.initTg();
            // this func will create user
            if(userStore.tgInitDataRaw) userStore.getUserData();
        }
    });

    if (import.meta.server) {
        const req = nuxtApp.ssrContext?.event.node.req;
        const cookies = req.headers.cookie || "";

        const userLangMatch = cookies.match(/userLanguage=([^;]*)/);
        const userLanguage = userLangMatch?.[1] ?
            language.SUPPORTED_LANGUAGES.find(_lang => _lang.name === userLangMatch[1]) || DEFAULT_LANGUAGE :
            DEFAULT_LANGUAGE;
        useState("userLanguage", () => userLanguage);
        useHead({
            htmlAttrs: {
                lang: userLanguage?.name,
                dir: userLanguage?.isRtl ? "rtl" : "ltr",
            },
        });
    }
});