const UNIQUE_ID = "__exone_click_away__";

function clickEventType(){
    return document.ontouchstart !== null ? "click" : "touchstart";
};
function clickAwayOnUnMounted(el: any) {
    document.removeEventListener(clickEventType(), el[UNIQUE_ID], false);
    delete el[UNIQUE_ID];
}
function clickAwayOnMounted(el: any, binding: any, vnode: any) {
    const vm = vnode.context;
    const callback = binding.value;

    if(typeof callback === "function" && !el[UNIQUE_ID]) {
        let isNextTick = false;
        setTimeout(() => {
            isNextTick = true;
        });
        el[UNIQUE_ID] = (event: Event) => {
            if((!el || !el.contains(event.target)) && isNextTick) {
                return callback.call(vm, event);
            }
        };
        document.addEventListener(clickEventType(), el[UNIQUE_ID]);
    }
}
function clickAwayOnUpdated(el: Element, binding: any, vnode: any) {
    const { value, oldValue } = binding;

    if(value !== oldValue) {
        clickAwayOnMounted(el, binding, vnode);
    }
}
const formatDirective = {
    name: "click-away",
    directive: {
        mounted: clickAwayOnMounted,
        updated: clickAwayOnUpdated,
        unmounted: clickAwayOnUnMounted,
    },
};

export default formatDirective;