import { retrieveLaunchParams, type LaunchParams, initUtils } from "@telegram-apps/sdk";

export function tgParams(){
    let result;
    try{
        result = retrieveLaunchParams();
    } catch(e) {
        console.error(e);
    }
    return result as LaunchParams;
}
export function tgShare(value: string) {
    const utils = initUtils();
    utils.openTelegramLink(`https://t.me/share/url?url=${value}`);
}
export function openTgBlankLink(link: string) {
    const utils = initUtils();
    if (utils && link.includes("t.me")) {
        utils.openTelegramLink(link);
    } else {
        window.open(link, "_blank");
    }
}