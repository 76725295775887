import rfdc from "rfdc";

export function stringArrayToObject(arr: string[]) {
    const result: Record<string, boolean> = {};

    if(Array.isArray(arr)) {
        for(const value of arr) {
            result[value] = true;
        }
    }

    return result;
}

export function objectToStringArray(obj: Record<string, boolean>) {
    const result: string[] = [];

    if(typeof obj === "object" && obj !== null) {
        for(const key in obj) {
            if(obj[key]) {
                result.push(key);
            }
        }
    }

    return result;
}

export function filterObject<T extends string | number | boolean>(object: Record<string, T>,
    value: T, strict = true): Record<string, T>
{
    const result: Record<string, T> = {};
    const compare = strict ? (a: any, b: any) => a === b : (a: any, b: any) => a == b;

    if(typeof object === "object" && object !== null) {
        for(const key in object) {
            if(compare(object[key], value)) {
                result[key] = object[key];
            }
        }
    }

    return result;
}

export function objectsEqualTo(object1: any, object2: any): boolean {
    let result = true;

    if(typeof object1 === "object" && typeof object2 === "object" && object1 !== null && object2 !== null) {
        const keys = Array.from(new Set([...Object.keys(object1), ...Object.keys(object2)]));

        for(const key of keys) {
            if(typeof object1[key] === "object" && typeof object2[key] === "object") {
                result = uObjectsEqualTo(object1[key], object2[key]);
            } else {
                result = object1[key] === object2[key];
            }

            if(result === false) break;
        }

        return result;
    } else {
        result = object1 === object2;
    }

    return result;
}

export function objectArrayToObject<T extends Record<string, any>>(items: T[], key: string) {
    const result: Record<string, T> = {};

    if(Array.isArray(items)) {
        for(const item of items) {
            result[item[key]] = item;
        }
    }

    return result;
}

const clone = rfdc();
export function uClone<T>(input: T) {
    return clone(input);
}