function onMounted(el: HTMLElement, binding: any) {
    const { value } = binding;
    const targetElement = el.querySelector(value);

    if(targetElement) {
        const rect = targetElement.getBoundingClientRect();
        el.scrollTo({ top: rect.top });
    }
}
const scrollInView = {
    name: "scroll-in-view",
    directive: {
        mounted: onMounted,
    },
};

export default scrollInView;