<template>
    <div dir="ltr" class="border border-border rounded-lg bg-secondary p-0.5 select-none capitalize">
        <div class="relative z-0 h-full" @click.stop="toggleSelected()">
            <div
                class="absolute w-full h-full transform rounded-md z-0 duration-200"
                :class="pivotClass"
                :style="pivotStyle" />
            <div class="flex items-center h-full flex-wrap min-w-max justify-between">
                <div
                    v-for="item of items"
                    :key="item.name"
                    class="z-10 h-full text-center cursor-pointer transition-colors duration-200 !capitalize"
                    :style="style"
                    :class="[
                        item.name === selected.name && selectClass,
                        userStore.isRtlLang ? 'pt-0.5' : 'py-px',
                        item.class,
                    ]"
                >
                    <span v-if="doTr" class="leading-[0.65rem] mt-px">{{ $t(item.label || item.name) }}</span>
                    <span v-else v-text="item.label || item.name" />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
// name: MSwitchSelect

const userStore = useUser();
interface IntSwitchSelectItem {
    name: string
    label: string
    pivotClass: string
    selectClass: string
    class: string
}
interface IntSwitchSelectProps {
    items: IntSwitchSelectItem[]
    modelValue: IntSwitchSelectItem
    doTr?: boolean
}

type IntSwitchSelectEmits = (event: "update:modelValue", value: IntSwitchSelectItem) => void

const emit = defineEmits<IntSwitchSelectEmits>();
const props = withDefaults(defineProps<IntSwitchSelectProps>(), {
    items: () => [],
    doTr: true,
});

const selected = ref(props.modelValue);
const defaultPivotClass = "bg-blue-500";
const defaultSelectClass = "text-white";
const pivotClass = ref("");
const selectClass = ref("");
const style = reactive<{
    height?: string
    width?: string
}>({});
const pivotStyle = reactive<{
    height?: string
    width?: string
    left?: string
}>({});
const selectedIndex = ref(0);

function toggleSelected() {
    selectedIndex.value = (selectedIndex.value + 1) % props.items.length;
    selected.value = props.items[selectedIndex.value];
}

function setClasses(_selected: string) {
    let number = 0;

    for (const item of props.items) {
        if (item.name === _selected) {
            break;
        }
        number++;
    }

    style.width = `${1 / toRaw(props).items.length * 100}%`;
    pivotStyle.width = `${1 / toRaw(props).items.length * 100}%`;
    pivotStyle.left = `calc(${number / props.items.length * 100}%)`;

    pivotClass.value = typeof props.items[number].pivotClass === "string" ? props.items[number].pivotClass : defaultPivotClass;
    selectClass.value = typeof props.items[number].selectClass === "string" ? props.items[number].selectClass : defaultSelectClass;
}

watch(() => props.modelValue, (value) => {
    selected.value = value;
});

watch(selected, (value) => {
    setClasses(selected.value.name);
    emit("update:modelValue", value);
});

watch(() => props.modelValue, () => {
    const _selectedIndex = props.items.findIndex(_item => _item.name === selected.value.name);
    selectedIndex.value = _selectedIndex > 0 ? _selectedIndex : 0;
}, { immediate: true });
onMounted(() => {
    setClasses(selected.value.name);
});
</script>